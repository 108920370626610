import { useRouter } from 'next/router'

import { Typography } from '@mui/material'
import type { Hit } from 'instantsearch.js'
import prettyBytes from 'pretty-bytes'

import type { AssetModel } from '@/types'

import { numberFormatTranslations } from '../../../utils/plain/numberFormatTranslations'

export const FileSizeCellRenderer = ({ row }: { row: Hit<AssetModel> }) => {
  const { fileSize } = row

  const { locale } = useRouter()

  const isNumberFormatTranslationNeeded = locale in numberFormatTranslations
  const typographyContent = isNumberFormatTranslationNeeded
    ? prettyBytes(parseInt(fileSize, 10)).replace(
        '.',
        // @ts-ignore
        numberFormatTranslations[locale].symbols.latn.decimal,
      )
    : prettyBytes(parseInt(fileSize, 10), { locale })

  if (!fileSize) return null

  return <Typography variant="body3dense">{typographyContent}</Typography>
}
