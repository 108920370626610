// You can temporarily install `@formatjs/intl-numberformat`, and check the file `node_modules/@formatjs/intl-numberformat/locale-data/<your language code>`to month translations and more.
export const numberFormatTranslations = {
  'az-AZ': {
    symbols: {
      latn: {
        decimal: ',',
      },
    },
  },
  'kk-KZ': {
    symbols: {
      latn: {
        decimal: ',',
      },
    },
  },
  'uz-UZ': {
    symbols: {
      latn: {
        decimal: ',',
      },
    },
  },
  'hy-AM': {
    symbols: {
      latn: {
        decimal: ',',
      },
    },
  },
  'ka-GE': {
    symbols: {
      latn: {
        decimal: ',',
      },
    },
  },
  'sq-AL': {
    symbols: {
      latn: {
        decimal: ',',
      },
    },
  },
  'mk-MK': {
    symbols: {
      latn: {
        decimal: ',',
      },
    },
  },
} as const
