// You can temporarily install `@formatjs/intl-datetimeformat`, and check the file `node_modules/@formatjs/intl-datetimeformat/locale-data/<your language code>`to month translations and more.
export const dateTranslations = {
  'kk-KZ': {
    month: {
      short: [
        'қаң.',
        'ақп.',
        'нау.',
        'сәу.',
        'мам.',
        'мау.',
        'шіл.',
        'там.',
        'қыр.',
        'қаз.',
        'қар.',
        'жел.',
      ],
    },
    dateFormat: 'YYYY ж. MMM',
  },
  'uz-UZ': {
    month: {
      short: [
        'yan',
        'fev',
        'mar',
        'apr',
        'may',
        'iyn',
        'iyl',
        'avg',
        'sen',
        'okt',
        'noy',
        'dek',
      ],
    },
    dateFormat: 'MMM, YYYY',
  },
  'hy-AM': {
    month: {
      short: [
        'հնվ',
        'փտվ',
        'մրտ',
        'ապր',
        'մյս',
        'հնս',
        'հլս',
        'օգս',
        'սեպ',
        'հոկ',
        'նոյ',
        'դեկ',
      ],
    },
    dateFormat: 'YYYY թ. MMMM',
  },
  'ka-GE': {
    month: {
      short: [
        'იან',
        'თებ',
        'მარ',
        'აპრ',
        'მაი',
        'ივნ',
        'ივლ',
        'აგვ',
        'სექ',
        'ოქტ',
        'ნოე',
        'დეკ',
      ],
    },
    dateFormat: 'MMM. YYYY',
  },
  'sq-AL': {
    month: {
      short: [
        'jan',
        'shk',
        'mar',
        'pri',
        'maj',
        'qer',
        'korr',
        'gush',
        'sht',
        'tet',
        'nën',
        'dhj',
      ],
    },
    dateFormat: 'MMMM YYYY',
  },
  'az-AZ': {
    month: {
      short: [
        'yan',
        'fev',
        'mar',
        'apr',
        'may',
        'iyn',
        'iyl',
        'avq',
        'sen',
        'okt',
        'noy',
        'dek',
      ],
    },
    dateFormat: 'MMM YYYY',
  },
  'mk-MK': {
    month: {
      short: [
        'јан.',
        'фев.',
        'мар.',
        'апр.',
        'мај',
        'јун.',
        'јул.',
        'авг.',
        'сеп.',
        'окт.',
        'ное.',
        'дек.',
      ],
    },
    dateFormat: 'MMM YYYY г.',
  },
}
